:root {
    --star-color: #ddd;
    --star-background: #fc0;
}


.disabled > a {
    color: #aaaaaa;
    cursor: default;
}

.sidebar-card-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
}

.three-column-slider-grid {
    display: grid;
    grid-template-columns: 1fr minmax(48rem, 1fr)  1fr;
}

.three-column-grid {
    display: grid;
    grid-template-columns: 1fr minmax(42rem, 1fr)  1fr;
    gap: 1rem;
    align-items: start;
}

.three-column-header-grid {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr minmax(42rem, 1fr)  1fr;
    gap: 1rem;
}

.active-voice {
    color: rgb(146, 39, 143);
    background-color: rgb(243, 232, 255);
    border-radius: 0.5rem;
}


.three-column-slider-grid .carousel.carousel-slider {
    padding: 0 3rem;
}

.news-gallery .carousel.carousel-slider {
    padding: 0;
}

.shop-categories-home a:not(:last-child) {
    border-right: 1px solid transparent;
}

.breadcrumb a::after {
    font-size: 0.8rem;
    padding: 0 0.8rem;
    color: black;
    content: "❯";
}

.products-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    line-height: 1;
}

.Stars::before {
    content: "★★★★★";
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.education-cutout-mask {
    mask-image: radial-gradient(circle 45px at 50% 0, transparent 45px, #000 0);
}

.edu_list::before {
    content: "›";
    font-size: 1.6rem;
    opacity: 0.4;
}

.edu_team_member:hover .edu_team_member_hover_content {
    display: block;
}

.gallery-grid {
    /* Prevent vertical gaps */
    line-height: 0;
    column-count: 4;
    column-gap: 8px;
}

.gallery-grid img {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto !important;
    margin-bottom: 8px;
}

.tab-bubble {
    position: relative;
    /*width: 325px;*/
    /*height: 100px;*/
    padding: 0;
    background: #ffffff;
    border-radius: 10px;
    border: #61b1f2 solid 3px;
}

.tab-bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 14px 13px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -14px;
}

.tab-bubble:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 16px 15px 0;
    border-color: #61b1f2 transparent;
    display: block;
    width: 0;
    z-index: 0;
    bottom: -19px;

}

.tab-bubble-left:after {
    left: 26px;
}

.tab-bubble-left:before {
    left: 24px;
}

.tab-bubble-right:after {
    right: 26px;
}

.tab-bubble-right:before {
    right: 24px;
}

.active-tab-bubble {

}

.hero-wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    /*background-size: 100%;*/
}

.hero-wave svg {
    position: relative;
    display: block;
    /*width: calc(148% + 1.3px);*/
    width: 100%;
    height: 113px;
    transform: rotateY(180deg);
}

.hero-wave .hero-wave-fill {
    fill: #ffffff;
}


/* Style the accordion chevron icon */
.accordion__icon {
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(225deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    /*background-color: white;*/
    transition: max-height 0.6s ease;
}



@media (max-width: 1000px) {
    .gallery-grid {
        column-count: 3;
    }
}

@media (max-width: 800px) {
    .gallery-grid {
        column-count: 2;
    }
}

@media (max-width: 400px) {
    .gallery-grid {
        column-count: 1;
    }
}


@media only screen and (max-width: 1280px) {
    .three-column-grid, .three-column-slider-grid {
        grid-template-columns: 1fr;
    }
}

/**************************************************************************curtain*/
/*#theatre {*/
/*    height: 100vh;*/
/*    width: 100%;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    overflow: hidden;*/
/*}*/

/*#curtain-left, #curtain-right {*/
/*    content: '';*/
/*    position: absolute;*/
/*    z-index: 9999;*/
/*    top: -100px;*/
/*    bottom: -30px;*/
/*    width: 61%;*/
/*    transition: all 4s ease-out;*/
/*    transform: rotate(0deg);*/
/*    background-color: #A90505;*/
/*    background-image: linear-gradient(90deg,  transparent 10%, rgba(255,255,255,.25) 50% ,transparent 65% );*/
/*    background-repeat: repeat;*/
/*    box-shadow: 0 0 10px #555 ,inset 0 -60px  60px -30px  rgba(255,255,255, 0.5), inset 0 90px  60px -30px  rgba(0,0,0, 0.5);*/
/*    background-size: 80px;*/
/*}*/

/*#curtain-left { left: -10% }*/

/*#curtain-right { right: -10% }*/

/*div#theatre em.open-curtain-left {*/
/*    width: 0;*/
/*    background-size: 1px;*/
/*    transition: all 4s ease;*/
/*    transform: rotate(15deg);*/
/*}*/

/*div#theatre em.open-curtain-right {*/
/*    width: 0;*/
/*    background-size: 1px;*/
/*    transition: all 4s ease;*/
/*    transform: rotate(-15deg);*/
/*}*/
